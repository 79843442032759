<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- <pre>
        {{ formModel }}
        </pre> -->
        <v-form v-model="formValid" ref="form">
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="2" md="2">
                <v-select
                  v-model="selectedLocale"
                  :items="languages"
                  :disabled="languages.length == 1"
                >
                  <template slot="selection" slot-scope="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="selectedLocale.flag" alt="" />
                    </span>
                    {{ slotProps.item.name }}
                  </template>
                  <template v-slot:item="slotProps">
                    <span class="symbol symbol-20 mr-3">
                      <img :src="slotProps.item.flag" alt="" />
                    </span>
                    <span class="navi-text">{{ slotProps.item.name }}</span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  v-if="formModel"
                  v-model="formModel.name"
                  :label="$t('FORMS.name')"
                  :rules="nameRules"
                  :id="dynamicID"
                  :error-messages="messages['name']"
                  @keyup="messages['name'] = ''"
                  disabled
                ></v-text-field>
                <v-text-field
                  v-if="
                    formModel &&
                    formModel.custom_fields &&
                    formModel.custom_fields.translations &&
                    formModel.custom_fields.translations[selectedLocale.lang]
                  "
                  v-model="
                    formModel.custom_fields.translations[selectedLocale.lang]
                      .title
                  "
                >
                </v-text-field>

                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  :config="{
                    type: 'text',
                    label: 'FORM_INPUT_NAMES.gtm',
                  }"
                  customFieldName="gtm"
                  :value="formModel.custom_fields.gtm"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'text',
                        label: 'FORM_INPUT_NAMES.recaptcha_site_key',
                      }"
                      customFieldName="recaptcha_site_key"
                      :value="formModel.custom_fields.recaptcha_site_key"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'text',
                        label: 'FORM_INPUT_NAMES.recaptcha_site_secret',
                      }"
                      customFieldName="recaptcha_site_secret"
                      :value="formModel.custom_fields.recaptcha_site_secret"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="4" md="4">
                <v-autocomplete
                  v-if="formModel && formModel.custom_fields"
                  v-model="formModel.custom_fields.selectedLanguages"
                  :items="locales"
                  chips
                  multiple
                  :label="$t('FORMS.select_site_language_order')"
                >
                  <template #selection="data">
                    <draggable
                      :id="data.index"
                      :list="formModel.custom_fields.selectedLanguages"
                      v-bind="dragOptionsChips"
                      :move="move"
                      @change="change"
                    >
                      <v-chip
                        draggable
                        v-model="
                          formModel.custom_fields.selectedLanguages[data.index]
                        "
                        :key="data.item"
                        close
                        color="bg-gray-100"
                        @click:close="
                          deleteChip(
                            data.item,
                            formModel.custom_fields.selectedLanguages
                          )
                        "
                        @mousedown.stop
                        @click.stop
                      >
                        {{ data.item }}
                      </v-chip>
                    </draggable>
                  </template>
                </v-autocomplete>
                <draggable
                  v-model="formModel.custom_fields.selectedLanguages"
                  v-bind="dragOptionsChips"
                >
                </draggable>
                {{ $t("FORM_INPUT_NAMES.select_public_languages") }}
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  :config="{
                    type: 'multi_checkbox',
                    label: 'FORM_INPUT_NAMES.select_public_languages',
                    items: publicLocales,
                  }"
                  customFieldName="publicLanguages"
                  :value="formModel.custom_fields.publicLanguages || []"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'text',
                        label: 'FORM_INPUT_NAMES.og_site_name',
                      }"
                      customFieldName="og_site_name"
                      :value="formModel.custom_fields.og_site_name"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'text',
                        label: 'FORM_INPUT_NAMES.og_title',
                      }"
                      customFieldName="og_title"
                      :value="formModel.custom_fields.og_title"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">

                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'multi_select',
                        multiple: false,
                        label: $t('FORM_INPUT_NAMES.og_type'),
                        items: og_types,
                      }"
                      customFieldName="og_type"
                      :value="formModel.custom_fields.og_type || 'website'"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'text',
                        label: 'FORM_INPUT_NAMES.og_url',
                      }"
                      customFieldName="og_url"
                      :value="formModel.custom_fields.og_url"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <CustomFieldComponent
                      v-if="formModel && formModel.custom_fields"
                      :config="{
                        type: 'textarea',
                        label: $t('FORM_INPUT_NAMES.og_description'),
                      }"
                      customFieldName="og_description"
                      :value="formModel.custom_fields.og_description"
                      @handleChangeCustomFieldValue="
                        handleChangeCustomFieldValue
                      "
                    >
                    </CustomFieldComponent>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  customFieldName="og_image"
                  :value="formModel.custom_fields.og_image || ''"
                  :config="{
                    type: 'media_selector',
                    maxFiles: 1,
                    selectButtonText: 'FORM_INPUT_NAMES.og_image',
                    initialColumnClass:
                      'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                  }"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
            </v-row> -->
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="12"> SMTP: </v-col>
              <v-col cols="12" sm="6" md="6">
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  :config="{
                    type: 'text',
                    label: 'FORM_INPUT_NAMES.sender_name',
                  }"
                  customFieldName="name"
                  :value="formModel.custom_fields.name"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  :config="{
                    type: 'text',
                    label: 'FORM_INPUT_NAMES.sender_email',
                  }"
                  customFieldName="email"
                  :value="formModel.custom_fields.email"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
            </v-row>
            <!-- <v-divider></v-divider>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  customFieldName="favicon"
                  :value="formModel.custom_fields.favicon || ''"
                  :config="{
                    type: 'media_selector',
                    maxFiles: 1,
                    selectButtonText: 'FORM_INPUT_NAMES.select_favicon',
                    initialColumnClass:
                      'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                  }"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <CustomFieldComponent
                  v-if="formModel && formModel.custom_fields"
                  customFieldName="logo"
                  :value="formModel.custom_fields.logo || ''"
                  :config="{
                    type: 'media_selector',
                    maxFiles: 1,
                    selectButtonText: 'FORM_INPUT_NAMES.select_logo',
                    initialColumnClass:
                      'col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12',
                  }"
                  @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                >
                </CustomFieldComponent>
              </v-col>
            </v-row> -->
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
// import { mapGetters } from "vuex";

import SiteService from "@/core/services/site.service.js";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import draggable from "vuedraggable";

export const ENDPOINT = "site/";

export const PERMISSION_TO = "system.site.";

const TRANSLATED_ATRIBUTES = [];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = ["title"];
export const INITIAL_CUSTOM_FIELDS = {
  name: "",
  email: "",
  // favicon: "",
  // logo: "",
  selectedLanguages: [],
  publicLanguages: [],
  gtm: "",
  recaptcha_site_key: "",
  recaptcha_site_secret: "",
  translations: {},
  // og_type: "",
  // og_title: "",
  // og_description: "",
  // og_url: "",
  // og_site_name: "",
  // og_image: "",
};

export const initialFormData = () => ({
  id: null,
  name: "",
  locales: [],
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "Sites",
  components: { CustomFieldComponent, draggable },

  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      cardTitle: this.$t("MENU.PAGE_SETTINGS"),

      routePath: "/cms/cookieManagement/settings/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),

      dialogDelete: false,
      endPoint: ENDPOINT,

      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      loader: false,
      formModel: Object.assign({}, initialFormData()),
      messages: [],
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      dragged: {
        from: -1,
        to: -1,
        newIndex: -1,
      },
      // selectedSync: [],
      og_types: ["website", "article"],
    };
  },

  computed: {
    // ...mapGetters(["siteCollection", "statuses"]),
    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },

    initialFormData() {
      return initialFormData;
    },
    locales() {
      if (!this.$store.getters.locales) return [];
      return this.$store.getters.locales;
    },

    publicLocales() {
      if (!this.formModel.custom_fields.selectedLanguages) return [];
      return this.formModel.custom_fields.selectedLanguages.map((item) => {
        return { label: item, value: item };
      });
    },

    dragOptionsChips() {
      return {
        animation: 200,
        group: "group",
        disabled: false,
        ghostClass: "ghost",
        sort: true,
      };
    },
  },

  methods: {
    loadModel() {
      this.selectedLocale = i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      });
      this.loader = true;
      this.formModel = Object.assign({}, initialFormData());
      ApiService.get(ENDPOINT + SiteService.getActiveSiteId())
        .then(({ data }) => {
          this.languages.forEach((language) => {
            if (!data.custom_fields.translations)
              data.custom_fields.translations = {};
            // data.custom_fields.translations = {};
            if (!data.custom_fields.translations[language.lang]) {
              data.custom_fields.translations[language.lang] = {};
              data.custom_fields.translations[language.lang]["title"] = "";
            }
          });

          this.formModel = Object.assign({}, data);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        /**Delete unused translations */
        // if (TRANSLATED_ATRIBUTES.length > 0) {
        //   let translations = {};
        //   i18nService.languages.forEach((item) => {
        //     let langCode = item.lang;
        //     let isTranslated = false;
        //     this.translatedAttributes.forEach((attribute) => {
        //       if (
        //         model.translations[langCode] &&
        //         model.translations[langCode][attribute] &&
        //         model.translations[langCode][attribute] != ""
        //       ) {
        //         isTranslated = true;
        //       }
        //     });
        //     if (isTranslated) {
        //       translations[langCode] = Object.assign(
        //         {},
        //         model.translations[langCode]
        //       );
        //     }
        //   });

        //   if (Object.keys(translations).length == 0) {
        //     translations[this.selectedLocale.lang] =
        //       model.translations[this.selectedLocale.lang];
        //   }
        //   model.translations = Object.assign({}, translations);
        // }
        /**Delete unused translations */
        this.loader = true;
        if (model.id) {
          // model.custom_fields.translations["hu"]["title"] = "magyar";

          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              console.log("save", model);
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    toggle: function (todo) {
      todo.done = !todo.done;
    },
    move: function (value) {
      this.dragged = {
        from: parseInt(value.from.id),
        to: parseInt(value.to.id),
        newIndex: value.draggedContext.futureIndex,
      };
    },
    change: function (value) {
      if (value.removed) {
        // insert
        this.formModel.custom_fields.selectedLanguages.splice(
          this.dragged.to + this.dragged.newIndex,
          0,
          this.formModel.custom_fields.selectedLanguages[this.dragged.from]
        );
        // delete
        if (this.dragged.from < this.dragged.to)
          // LTR
          this.formModel.custom_fields.selectedLanguages.splice(
            this.dragged.from,
            1
          );
        // RTL
        else
          this.formModel.custom_fields.selectedLanguages.splice(
            this.dragged.from + 1,
            1
          );
      }
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },
  },

  mounted() {
    this.loadModel();

    // this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
